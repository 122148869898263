import React from "react";

function Security() {
  return (
    <div style={{ marginTop: 20, padding: 10 }}>
      <p>Security</p>
    </div>
  );
}

export default Security;
